<template>
<div id="videoLessons">
    <mainheader @changeLang="lang = $event"></mainheader>
    <ChooseTariffModal></ChooseTariffModal>
    <mainbox></mainbox>
      <section>
              <!-- ===== video lessons ===== -->
        <div class="video-lessons">
            <div class="container">
                       <div v-if="lang == 'ru'">
                          <h1>Видеоуроки</h1>
                <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-12 m-auto">
                            <div class="lessons-box">
                                <div class="lessons-item">
                                  <a href="https://www.youtube.com/watch?v=MWdhfXCkRh8" target="_blank">
                                    <div class="d-flex align-items-center margin-top-15">
                                      <div><b-icon icon="play-circle-fill"></b-icon></div>
                                    <div><span>Согласование документов внутри Faktura.uz</span></div>
                                    </div>
                                  </a>
                                  <a href="https://www.youtube.com/watch?v=RiK0XtzLvnM" target="_blank">
                                    <div class="d-flex align-items-center margin-top-15">
                                      <div><b-icon icon="play-circle-fill"></b-icon></div>
                                    <div><span>Установка модулей в систему 1С</span></div>
                                    </div>
                                  </a>
                                  <a href="https://www.youtube.com/watch?v=T0g1M3Wba_0" target="_blank">
                                    <div class="d-flex align-items-center margin-top-15">
                                      <div><b-icon icon="play-circle-fill"></b-icon></div>
                                    <div><span>Процесс создания доверенностей</span></div>
                                    </div>
                                  </a>
                                  <a href="https://www.youtube.com/watch?v=JbtEQhfa_M8" target="_blank">
                                    <div class="d-flex align-items-center margin-top-15">
                                      <div><b-icon icon="play-circle-fill"></b-icon></div>
                                    <div><span>Проблемы со входом в аккаунт</span></div>
                                    </div>
                                  </a>
                                  <a href="https://www.youtube.com/watch?v=CScG_0VxVnU" target="_blank">
                                    <div class="d-flex align-items-center margin-top-15">
                                      <div><b-icon icon="play-circle-fill"></b-icon></div>
                                    <div><span>Создание исправленной счет-фактуры</span></div>
                                    </div>
                                  </a>
                                  <a href="https://www.youtube.com/watch?v=V1oxOeq908k" target="_blank">
                                    <div class="d-flex align-items-center margin-top-15">
                                      <div><b-icon icon="play-circle-fill"></b-icon></div>
                                    <div><span>Массовая синхронизация</span></div>
                                    </div>
                                  </a>
                                  <a href="https://www.youtube.com/watch?v=2yooH6PsYvs" target="_blank">
                                    <div class="d-flex align-items-center margin-top-15">
                                      <div><b-icon icon="play-circle-fill"></b-icon></div>
                                    <div><span>синхронизация от Faktura.uz</span></div>
                                    </div>
                                  </a>
                                  <a href="https://www.youtube.com/watch?v=CjT3mqxBf_s" target="_blank">
                                    <div class="d-flex align-items-center margin-top-15">
                                      <div><b-icon icon="play-circle-fill"></b-icon></div>
                                    <div><span>3 этапа регистрации в системе Faktura.uz</span></div>
                                    </div>
                                  </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <div class="lessons-box">
                                <div class="lessons-item">
                                  <a href="https://www.youtube.com/watch?v=5nI4VmNIEec" target="_blank">
                                    <div class="d-flex align-items-center margin-top-15">
                                      <div><b-icon icon="play-circle-fill"></b-icon></div>
                                    <div><span>Массовое подписание документов</span></div>
                                    </div>
                                  </a>
                                  <a href="https://www.youtube.com/watch?v=2YJi3hKGGzk" target="_blank">
                                    <div class="d-flex align-items-center margin-top-15">
                                      <div><b-icon icon="play-circle-fill"></b-icon></div>
                                    <div><span>Архив менеджер</span></div>
                                    </div>
                                  </a>
                                  <a href="https://www.youtube.com/watch?v=k2aQiaHdxIc" target="_blank">
                                    <div class="d-flex align-items-center margin-top-15">
                                      <div><b-icon icon="play-circle-fill"></b-icon></div>
                                    <div><span>Создание договора</span></div>
                                    </div>
                                  </a>
                                  <a href="https://www.youtube.com/watch?v=CjFvdcr79zw" target="_blank">
                                    <div class="d-flex align-items-center margin-top-15">
                                      <div><b-icon icon="play-circle-fill"></b-icon></div>
                                    <div><span>Акт-сверки</span></div>
                                    </div>
                                  </a>
                                  <a href="https://www.youtube.com/watch?v=tFsJIt2rHqY" target="_blank">
                                    <div class="d-flex align-items-center margin-top-15">
                                      <div><b-icon icon="play-circle-fill"></b-icon></div>
                                    <div><span>Отправка писем через гибридную почту</span></div>
                                    </div> 
                                  </a>

                                  <!-- -------------------------- -->
                                  <a href="https://www.youtube.com/watch?v=4X4XbTBXcTs" target="_blank">
                                    <div class="d-flex align-items-center margin-top-15">
                                      <div><b-icon icon="play-circle-fill"></b-icon></div>
                                    <div><span>Регистрация в систему (новая форма)</span></div>
                                    </div> 
                                  </a>
                                  <a href="https://www.youtube.com/watch?v=J3Sgf-KmFxw" target="_blank">
                                    <div class="d-flex align-items-center margin-top-15">
                                      <div><b-icon icon="play-circle-fill"></b-icon></div>
                                    <div><span>Сертификат ЭЦП</span></div>
                                    </div> 
                                  </a>
                                  <a href="https://www.youtube.com/watch?v=GD5Cn_uZZrc" target="_blank">
                                    <div class="d-flex align-items-center margin-top-15">
                                      <div><b-icon icon="play-circle-fill"></b-icon></div>
                                    <div><span>пополнение счета и тарифы</span></div>
                                    </div> 
                                  </a>
                                  <!-- -------------------------- -->
                                  

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <div class="lessons-box">
                                <div class="lessons-item">
                                  <a href="https://www.youtube.com/watch?v=CScG_0VxVnU" target="_blank">
                                    <div class="d-flex align-items-center margin-top-15">
                                      <div><b-icon icon="play-circle-fill"></b-icon></div>
                                    <div><span>Исправленная счет-фактура</span></div>
                                    </div>
                                  </a>
                                  <a href="https://youtu.be/yvf04o2cRFw" target="_blank">
                                    <div class="d-flex align-items-center margin-top-15">
                                      <div><b-icon icon="play-circle-fill"></b-icon></div>
                                    <div><span>Расширеннная синхронизация Faktura.uz</span></div>
                                    </div>
                                  </a>
                                  <a href="https://www.youtube.com/watch?v=UTQ5ZwvNODw" target="_blank">
                                    <div class="d-flex align-items-center margin-top-15">
                                      <div><b-icon icon="play-circle-fill"></b-icon></div>
                                    <div><span>Счет-фактуры с новыми дополнениями</span></div>
                                    </div>
                                  </a>
                                  <a href="https://www.youtube.com/watch?v=qmnkpFLPJLs" target="_blank">
                                    <div class="d-flex align-items-center margin-top-15">
                                      <div><b-icon icon="play-circle-fill"></b-icon></div>
                                    <div><span>E-imzo установка</span></div>
                                    </div>
                                  </a>
                                  <a href="https://www.youtube.com/watch?v=Mj34weqNj9g" target="_blank">
                                    <div class="d-flex align-items-center margin-top-15">
                                      <div><b-icon icon="play-circle-fill"></b-icon></div>
                                    <div><span>Как добавить сотрудника</span></div>
                                    </div>
                                  </a>
                                  <a href="https://youtu.be/qeHQ13vZuRM" target="_blank">
                                    <div class="d-flex align-items-center margin-top-15">
                                      <div><b-icon icon="play-circle-fill"></b-icon></div>
                                    <div><span>Хранение документов</span></div>
                                    </div>
                                  </a>
                                  <a href="https://youtu.be/t6P-oAjqoLg" target="_blank">
                                    <div class="d-flex align-items-center margin-top-15">
                                      <div><b-icon icon="play-circle-fill"></b-icon></div>
                                    <div><span>С чего начать</span></div>
                                    </div>
                                  </a>

                                </div>
                            </div>
                        </div>
                </div>
                       </div>
                <div v-else>
                  <h1>Видео дарсликлар</h1>
                <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-12 ">
                      <div class="lessons-box">
                          <div class="lessons-item">
                            <a href="https://www.youtube.com/watch?v=b8S7YrqnSgQ" target="_blank">
                              <div class="d-flex align-items-center margin-top-15">
                                <div><b-icon icon="play-circle-fill"></b-icon></div>
                              <div><span>Ҳужжатларни ҳужжатларни жараёни</span></div>
                              </div>
                            </a>
                            <a href="https://www.youtube.com/watch?v=QDsAEUCKZdo" target="_blank">
                              <div class="d-flex align-items-center margin-top-15">
                                <div><b-icon icon="play-circle-fill"></b-icon></div>
                              <div><span> Faktura.uz модулларни 1С тизимга ўрнатиш жараёни</span></div>
                              </div>
                            </a>
                            <a href="https://www.youtube.com/watch?v=v0L2un6mfl8" target="_blank">
                              <div class="d-flex align-items-center margin-top-15">
                                <div><b-icon icon="play-circle-fill"></b-icon></div>
                              <div><span>Ишончнома яратиш</span></div>
                              </div>
                            </a>
                            <a href="https://www.youtube.com/watch?v=xM2LvebLHOA" target="_blank">
                              <div class="d-flex align-items-center margin-top-15">
                                <div><b-icon icon="play-circle-fill"></b-icon></div>
                              <div><span>Аккаунтга тегишли муаммолар</span></div>
                              </div>
                            </a>
                            <a href="https://www.youtube.com/watch?v=CScG_0VxVnU" target="_blank">
                              <div class="d-flex align-items-center margin-top-15">
                                <div><b-icon icon="play-circle-fill"></b-icon></div>
                              <div><span>Тўғирланган ҳисоб-фактура</span></div>
                              </div>
                            </a>
                            <a href="https://www.youtube.com/watch?v=szVUeeske-o" target="_blank">
                              <div class="d-flex align-items-center margin-top-15">
                                <div><b-icon icon="play-circle-fill"></b-icon></div>
                              <div><span>Манфий рақамли ҳисоб-фактура </span></div>
                              </div>
                            </a>
                          </div>
                      </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12 ">
                      <div class="lessons-box">
                          <div class="lessons-item">
                            <a href="https://www.youtube.com/watch?v=5nI4VmNIEec" target="_blank">
                              <div class="d-flex align-items-center margin-top-15">
                                <div><b-icon icon="play-circle-fill"></b-icon></div>
                              <div><span> Ҳужжатларни оммавий имзоланиши</span></div>
                              </div>
                            </a>
                            <a href="https://www.youtube.com/watch?v=M3gnCsGQBU0" target="_blank">
                              <div class="d-flex align-items-center margin-top-15">
                                <div><b-icon icon="play-circle-fill"></b-icon></div>
                              <div><span>Архив менежер</span></div>
                              </div>
                            </a>
                            <a href="https://youtu.be/UbnKqFV0PAk" target="_blank">
                              <div class="d-flex align-items-center margin-top-15">
                                <div><b-icon icon="play-circle-fill"></b-icon></div>
                              <div><span>Шартнома яратиш</span></div>
                              </div>
                            </a>
                            <a href=" https://www.youtube.com/watch?v=PIZsep7HZSw" target="_blank">
                              <div class="d-flex align-items-center margin-top-15">
                                <div><b-icon icon="play-circle-fill"></b-icon></div>
                              <div><span>Солиштирма далолатнома</span></div>
                              </div>
                            </a>
                            <a href="https://www.youtube.com/watch?v=NlmeRmHfjoA" target="_blank">
                              <div class="d-flex align-items-center margin-top-15">
                                <div><b-icon icon="play-circle-fill"></b-icon></div>
                              <div><span>Гибрид почта орқали хатларни жўнатиш</span></div>
                              </div> 
                            </a>
                            <a href="https://www.youtube.com/watch?v=39ILO_UbD20" target="_blank">
                              <div class="d-flex align-items-center margin-top-15">
                                <div><b-icon icon="play-circle-fill"></b-icon></div>
                              <div><span>Товар-транспорт юк хужжати яратилиши</span></div>
                              </div> 
                            </a>
                          </div>
                      </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12 ">
                      <div class="lessons-box">
                          <div class="lessons-item">
                            <a href="https://www.youtube.com/watch?v=494zbTz_YOM" target="_blank">
                              <div class="d-flex align-items-center margin-top-15">
                                <div><b-icon icon="play-circle-fill"></b-icon></div>
                              <div><span>Синхронизация кенгайтмаси</span></div>
                              </div>
                            </a>
                            <a href="https://www.youtube.com/watch?v=IwlVBawRq3c" target="_blank">
                              <div class="d-flex align-items-center margin-top-15">
                                <div><b-icon icon="play-circle-fill"></b-icon></div>
                              <div><span>Ҳисобварак-фактура яратишда янги қўшимчалар</span></div>
                              </div>
                            </a>
                            <a href="https://www.youtube.com/watch?v=lw8K7rQOoc0" target="_blank">
                              <div class="d-flex align-items-center margin-top-15">
                                <div><b-icon icon="play-circle-fill"></b-icon></div>
                              <div><span>E-IMZO ўрнатиш жараени</span></div>
                              </div>
                            </a>
                            <a href="https://www.youtube.com/watch?v=jURNmXF1lGU" target="_blank">
                              <div class="d-flex align-items-center margin-top-15">
                                <div>
                                  <b-icon icon="play-circle-fill"></b-icon>
                                  </div>
                              <div>
                                <span>Корпоратив карталар мониторинг</span>
                              </div>
                              </div>
                            </a>
                          </div>
                      </div>
                    </div>
                 </div>
                </div>
            </div>
        </div>
              <!-- ===== animation ===== -->
        <div class="video-lessons">
            <div class="container">
              <div v-if="lang == 'ru'">
                <h2>Возможности</h2>
                <div class="row">
                  <div class="col-lg-4 col-md-4 col-sm-12 ">
                      <div class="lessons-box">
                          <div class="lessons-item">
                            <a href="https://www.youtube.com/watch?v=zxz0LpOibbQ" target="_blank">
                              <div class="d-flex align-items-center margin-top-15">
                                <div><b-icon icon="play-circle-fill"></b-icon></div>
                              <div><span>Почему бухгалтеры выбирают Faktura.uz</span></div>
                              </div>
                            </a>
                            <a href="https://www.youtube.com/watch?v=xcrJ_o4_nmc" target="_blank">
                              <div class="d-flex align-items-center margin-top-15">
                                <div><b-icon icon="play-circle-fill"></b-icon></div>
                              <div><span>Что такое гибридная почта</span></div>
                              </div>
                            </a>
                            <a href="https://www.youtube.com/watch?v=sqT9R3OZYyA" target="_blank">
                              <div class="d-flex align-items-center margin-top-15">
                                <div><b-icon icon="play-circle-fill"></b-icon></div>
                              <div><span>Интеграция 1С с интернет банкингом</span></div>
                              </div>
                            </a>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-12 ">
                      <div class="lessons-box">
                          <div class="lessons-item">
                            <a href="https://www.youtube.com/watch?v=Z6bxcgsnvis" target="_blank">
                              <div class="d-flex align-items-center margin-top-15">
                                <div><b-icon icon="play-circle-fill"></b-icon></div>
                              <div><span>Инновационное продвижение вашего бизнеса</span></div>
                              </div>
                            </a>
                            <a href="https://www.youtube.com/watch?v=RzFrlnP-XyQ" target="_blank">
                              <div class="d-flex align-items-center margin-top-15">
                                <div><b-icon icon="play-circle-fill"></b-icon></div>
                              <div><span>Фильтр для нахождения ваших документов</span></div>
                              </div>
                            </a>
                            <a href="https://www.youtube.com/watch?v=qIkGnyFvCSU" target="_blank">
                              <div class="d-flex align-items-center margin-top-15">
                                <div><b-icon icon="play-circle-fill"></b-icon></div>
                              <div><span>Сортировка документов</span></div>
                              </div>
                            </a>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-12 ">
                      <div class="lessons-box">
                          <div class="lessons-item">
                            <a href="https://www.youtube.com/watch?v=OY9CvZ2_coQ" target="_blank">
                              <div class="d-flex align-items-center margin-top-15">
                                <div><b-icon icon="play-circle-fill"></b-icon></div>
                              <div><span>Подключение телеграм бота Faktura.EDI</span></div>
                              </div>
                            </a>
                            <a href="https://www.youtube.com/watch?v=IHneRb4Xb7Q" target="_blank">
                              <div class="d-flex align-items-center margin-top-15">
                                <div><b-icon icon="play-circle-fill"></b-icon></div>
                              <div><span>Faktura.PAY</span></div>
                              </div>
                            </a>
                            <a href="https://www.youtube.com/watch?v=PQw2GDA1Tow" target="_blank">
                              <div class="d-flex align-items-center margin-top-15">
                                <div><b-icon icon="play-circle-fill"></b-icon></div>
                              <div><span>Faktura.EDI</span></div>
                              </div>
                            </a>
                          </div>
                      </div>
                  </div>
                  </div>
              </div>
                <!-- uz -->
                <div v-else>
                  <h2>Имкониятлар</h2>
                <div class="row">
                  <div class="col-lg-4 col-md-4 col-sm-12 ">
                      <div class="lessons-box">
                          <div class="lessons-item">
                            <a href="https://www.youtube.com/watch?v=MvGUI9JobYU" target="_blank">
                              <div class="d-flex align-items-center margin-top-15">
                                <div><b-icon icon="play-circle-fill"></b-icon></div>
                              <div><span>Интеграция 1С с интернет банкингом (узб)</span></div>
                              </div>
                            </a>
                            <a href="https://www.youtube.com/watch?v=z4VPRwUx3k8" target="_blank">
                              <div class="d-flex align-items-center margin-top-15">
                                <div><b-icon icon="play-circle-fill"></b-icon></div>
                              <div><span>Гибрид почта</span></div>
                              </div>
                            </a>
                            <a href="https://www.youtube.com/watch?v=Tmcdcdl_TvE" target="_blank">
                              <div class="d-flex align-items-center margin-top-15">
                                <div><b-icon icon="play-circle-fill"></b-icon></div>
                              <div><span>FAKTURA UZ</span></div>
                              </div>
                            </a>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-12 ">
                      <div class="lessons-box">
                          <div class="lessons-item">
                            <a href="https://www.youtube.com/watch?v=XeFarKqulv4" target="_blank">
                              <div class="d-flex align-items-center margin-top-15">
                                <div><b-icon icon="play-circle-fill"></b-icon></div>
                              <div><span>Ҳужжатларни саралаши</span></div>
                              </div>
                            </a>
                            <a href="https://www.youtube.com/watch?v=pvQfjEIZ1_Q" target="_blank">
                              <div class="d-flex align-items-center margin-top-15">
                                <div><b-icon icon="play-circle-fill"></b-icon></div>
                              <div><span>Хабарномалар</span></div>
                              </div>
                            </a>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-12 ">
                      <div class="lessons-box">
                          <div class="lessons-item">
                            <a href="https://www.youtube.com/watch?v=YQge1Z68zTI" target="_blank">
                              <div class="d-flex align-items-center margin-top-15">
                                <div><b-icon icon="play-circle-fill"></b-icon></div>
                              <div><span>Bot.CRM</span></div>
                              </div>
                            </a>
                            <a href="https://www.youtube.com/watch?v=pkHzl2zGrgw" target="_blank">
                              <div class="d-flex align-items-center margin-top-15">
                                <div><b-icon icon="play-circle-fill"></b-icon></div>
                              <div><span>FAKTURA EDO</span></div>
                              </div>
                            </a>
                          </div>
                      </div>
                  </div>
                  </div>
                </div>
            </div>
        </div>
      </section>
  
        <mainfooter></mainfooter>
    </div>
</template>


<script>
import mainbox from './mainbox.vue'
import mainheader from "./mainheader.vue"
import mainfooter from "./mainfooter.vue"
import ChooseTariffModal from './modal/ChooseTariffModal.vue'

export default {
  data(){
    return {
      name: 'videoLessons',
      lang: this.getLanguage || "uz"
    }
  },
  components: {
    mainheader,
    mainbox,
    mainfooter,
    ChooseTariffModal
},
  computed:{
    getLanguage(){
      console.log(true);
      return JSON.parse(localStorage.getItem("translation")).localeName
    }
  },
       metaInfo() {
        return { 
            title: "Видеоуроки по пользованию системой эдо Faktura.EDO",
            meta: [
                { name: 'description', content:  'Видеоролик где подробно описывается все взаимодействие с системой электронного документооборота Faktura.EDO Остались вопросы? Звоните ☎️: +998 (71) 200-00-13'},
            ]
        }
    },
}
</script>

<style>

a{
  text-decoration: none !important;
}

</style>


